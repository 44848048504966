import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
// import CssBaseline from '@material-ui/core/CssBaseline'
// import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
// import AppBar from '@material-ui/core/AppBar'
// import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios';
import Sample from '../../../Commonscreens/sampleTextField';
import customStyle from '../../CSS/stylesheet_UHS';
import { findUser } from './Config'


const useStyles = makeStyles(theme => ({


    TextFieldFont: {
        "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue",
            fontSize: '5px'
        }
    },
    grid: {
        // paddingTop: '10px',
        paddingBottom: '5px',
        background: 'white',
        marginTop: '5px;'
    },
    searchGrid: {
        // paddingTop: '15px !important'
    },
    formControl: {
        width: '80%',
        marginLeft: '15px'
    },
    searchBar: {
        width: '100%',
        marginLeft: '15px'
    },
    btnContainer: {
        marginTop: '15px',
        marginLeft: '15px',
    },
    loader: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#7878bb47',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0
    }
}));

export default function ManageCognitoUser() {
    // var curamOrganization = Buffer.from(`CURAMLIFE;${new Date()}`).toString('base64')
    const classes = useStyles();
    //const baseURL = 'https://dev.fabric.carynhealth.com/api/v1/';
    const [baseURL, setbaseURL] = useState('');
    const [userPoolList, setUserPool] = useState([]);
    const [selectedEnv, setSelectedEnv] = useState(-1);
    const [selectedEnvvalue, setSelectedEnvValue] = useState(-1);
    const [selectedUserPoolIndex, setUserPoolIndx] = useState(-1);
    const [userName, setUserName] = useState('');
    const [ccEmail, setCcEmail] = useState('');
    const [showLoader, toggleLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setError] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false);
    const [isMemberPortal, setIsMemberPortal] = useState(false);

    useEffect(() => {

        getUserPool()



    }, []);

    const getUserPool = async () => {
        toggleLoader(true)
        await getToken();
        //let baseURL=process.env.REACT_APP_BASE_URL
        
        let URL = process.env.REACT_APP_ENV
        const email = localStorage.getItem('email');
        if (findUser(email)) {
            URL = 'all'
        }
        axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/userpool/' + URL, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('csrtoken'),
                },
            })
            .then((resp) => {
                console.log(resp.data);
                let list = formatResponse(resp.data);
                if (list.length > 0) {
                    setUserPool(list);
                    setSelectedEnv(0);
                    setUserPoolIndx(0);
                }
                toggleLoader(false);
            })
            .catch((err) => {
                toggleLoader(false);
            });

        // login()
        //     .then((token) => {
        //         console.log(token)

        //     });
    }

    const formatResponse = (response) => {
        let list = []
        Object.keys(response).forEach((key) => {
            list.push({ env: key, list: response[key] })
        });
        console.log(list)
        return list;
    }

    const changeEnv = (event) => {
        console.log(event.target.value)
        setSelectedEnv(event.target.value);
        setUserPoolIndx(0);
        setMessage('');
        setMemberPortal(event.target.value, 0);
    }

    const changeUserPool = (event) => {
        console.log(event.target.value)
        setUserPoolIndx(event.target.value)
        setMessage('');
        setMemberPortal(selectedEnv, event.target.value);
    }

    const setMemberPortal = (Env, UserPoolIndex) => {
        if (userPoolList[Env].list[UserPoolIndex].userpoolName === 'Member-portal') {
            setIsMemberPortal(true);
            setIsSubmit(false);
        } else {
            setIsMemberPortal(false);
            setIsSubmit(true);
        }
    }

    const submitForm = () => {

        let userpoolId = userPoolList[selectedEnv].list[selectedUserPoolIndex].userpoolId;
        let username = userName
        console.log(userpoolId, username);
        setMessage('');
        resendEmail(userpoolId, username)
    }

    const resendEmail = (userpoolId, username) => {
        console.log('selected environment :' + userPoolList[selectedEnv].env)
        let currentEnv = userPoolList[selectedEnv].env;
        let request = {
            "cognitoUserPool": userpoolId,
            "username": username,
            ccemail: ccEmail,
        }
        toggleLoader(true)
        let baseURL = null
        //based on selected environment switch api between dev and prod.AWS configs are API level
        if (!currentEnv.toLowerCase().includes('development')) {
            baseURL = 'https://testfabric.carynhealth.com/api/v1/';
        }
        else {
            baseURL = 'https://dev.fabric.carynhealth.com/api/v1/';
        }

        axios.post(baseURL + 'csrportal/resetuser', request,{
            headers: {
                // 'X-Tenant-Id': curamOrganization,
                Authorization: 'Bearer ' + localStorage.getItem('CurambearerToken')
            }
        }).then((resp) => {
            console.log(resp)
            toggleLoader(false)
            setError(false);
            setMessage(resp.data);
            setTimeout(() => {
                setMessage('')
            }, 3000);
        }).catch((err) => {
            toggleLoader(false)
            if (err.response.request.status != 409) {
                setError(true)
            } else {
                setError(false)
            }
            if (err.response && err.response.data && err.response.data.message) {
                setMessage(err.response.data.message)
            } else {
                setMessage('Please try again');

            }
        });
    }

    const getToken = async () => {
        let request = {
            "username": process.env.REACT_APP_AUTH_USERNAME,
            "password": process.env.REACT_APP_AUTH_TOKEN_PASS
        }
        await axios
            .post(process.env.REACT_APP_BaseAPIURL + 'api/v1/login', request)
            .then((response) => {
                if (response.data.message) {
                    console.log('failed to get token');
                    alert(response.data.message);
                } else {
                    let bearer = response['headers'].authorization;
                    var array = bearer.split('Bearer ');
                    localStorage.setItem('csrtoken', array[1]);
                }
            });
    };

    const validateEmail = (mail) => {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(mail);

    }

    const checkStatus = (userpoolId, email) => {

        let request = {
            cognitoUserPool: userpoolId,
            username: email,
        };
        getToken();
        let baseURL = null;
        let currentEnv = userPoolList[selectedEnv].env;
        //based on selected environment switch api between dev and prod.AWS configs are API level
        if (!currentEnv.toLowerCase().includes('development')) {
            baseURL = 'https://testfabric.carynhealth.com/api/v1/';
        } else {
            baseURL = 'https://dev.fabric.carynhealth.com/api/v1/';
        }

        axios
            .post(baseURL + 'memberportal/getuser', request, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('csrtoken'),
                },
            })
            .then((resp) => {
                console.log(resp.data.userStatus);
                if (resp.data.userStatus === 'CONFIRMED') {
                    setMessage(
                        `User ${email} is confirmed and can reset it by clicking the 'Forgot your password?'`
                    );
                    setIsSubmit(false);
                    setError(false);
                } else if (resp.data.userStatus === 'FORCE_CHANGE_PASSWORD') {
                    setMessage('');
                    setIsSubmit(true);
                } else {
                    setMessage('');
                    setIsSubmit(true);
                }
            })
            .catch((err) => {
                console.log(err);
                setMessage(`User not found`);
                setError(true);
            });
    };

    const submitFormCheck = (event) => {
        event.preventDefault();
        let userpoolId =
            userPoolList[selectedEnv].list[selectedUserPoolIndex].userpoolId;
        let username = userName;
        console.log(userpoolId, username);
        setMessage('');
        checkStatus(userpoolId, username);
    };

    return (

        <Container maxWidth="lg" style={{ background: 'white', marginTop: '0px', minHeight: '250px', padding: '0', position: 'relative' }}>

            <form onSubmit={submitFormCheck} className="user-mgmt-form" style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>

                    <div style={{width: '440px', margin:'10px'}}>
                        <FormControl>
                            <InputLabel shrink htmlFor="age-native-simple">Environment</InputLabel>
                            <Select
                                native
                                onChange={changeEnv}
                                style={customStyle.myCustomDropdown}
                            >
                                {
                                    userPoolList.map(((item, index) => {
                                        return <option key={item.env} value={index}>{item.env}</option>
                                    }))
                                }
                            </Select>
                        </FormControl>
                        </div>

                        <div style={{width: '440px', margin:'10px'}}>
                        <FormControl>
                            <InputLabel shrink htmlFor="age-native-simple">User Pool:</InputLabel>
                            <Select
                                native
                                onChange={changeUserPool}
                                style={customStyle.myCustomDropdown}
                            >
                                {
                                    userPoolList[selectedEnv] && userPoolList[selectedEnv].list.map((userpool, index) => {
                                        return <option key={userpool.userpoolId} value={index}>{userpool.userpoolName}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                        </div>

                        <div style={{width: '440px', margin:'10px'}}>
                        {/* <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Bank_Name'} reqFlag={true} label={'Bank Name'} value={this.state.bankName} disable={false} style={CustomeCss.textField} length={120} fieldType={'bank_name'} errMsg={'Enter valid bank name'} helperMsg={'Bank name required'} parentDetails={{ label: 'bank_name' }} key={0}></Sample> */}
                        <Sample setChild={(val) => { setUserName(val); setMessage('') }} name={'Email'} reqFlag={true} label={'Email'} value={userName} disable={false} style={customStyle.textFieldWrpAgent} length={120} fieldType={'text'} errMsg={'Enter valid Email'} helperMsg={'Email required'} parentDetails={{ label: 'Email' }} key={0}></Sample>

                    {isMemberPortal && (
                        <Button
                            size='small'
                            color='primary'
                            variant='contained'
                            type='submit'
                            style={{ margin: '15px 0 0', position: 'absolute', right: '11%' }}
                            // onClick={() => checkStatus(userName)}
                            disabled={!validateEmail(userName)}
                        >
                            Check
                        </Button>
                    )}
                </div>
                {isMemberPortal && (
                    <div style={{ width: '440px', margin: '10px' }}>
                        <Sample
                            setChild={(val) => setCcEmail(val)}
                            name={'CCEmail'}
                            reqFlag={false}
                            label={'CC Email'}
                            value={ccEmail}
                            disable={false}
                            style={customStyle.textFieldWrpAgent}
                            length={120}
                            fieldType={'email'}
                            errMsg={'Enter valid CC Email'}
                            parentDetails={{ label: 'CCEmail' }}
                        ></Sample>
                    </div>
                )}
                        {/* <Sample value={userName} fieldType="email" type="email" autoFocus required className={classes.searchBar} label="Email" style={customStyle.textFieldWrpAgent}
                            InputProps={{
                                classes: {
                                    input: classes.TextFieldFont,
                                },
                                name: "email",
                                required: true
                            }}
                            onChange={(e) => {
                                console.log('dev - - - ', e.target.value);
                                setUserName(e.target.value)
                            }}
                        /> */}

                        <div>
                        <FormControl className={classes.btnContainer} >
                            <Button size="small" color="primary" variant="contained"
                                type="submit"
                                onClick={() => submitForm()} 
                                disabled={!validateEmail(userName)}
                            >Resend Email</Button>
                        </FormControl>
                        </div>

            </form>
            <div style={{
                padding: '10px 35px',
                background: isError ? 'rgb(208 53 53)' : 'rgb(30 144 49)',
                color: 'white',
                margin: '10px 30px',
                display: message.length > 0 ? 'block' : 'none'
            }}>
                <p>{message}</p>
            </div>
            <div className={classes.loader} style={{
                display: showLoader ? 'flex' : 'none'
            }}>
                <CircularProgress />
            </div>
        </Container>

    );
}