import React from 'react';
import { useState, useEffect } from 'react'
// import { connect, useDispatch } from "react-redux";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import './paidthru.scss';
import PaidThruReport from './PaidThruReport'
// import apiservice from '../../utils/apiservice';
// import { TOGGLE_GLOBAL_LOADER } from '../../actions/homeActions';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import HomeIcon from '@material-ui/icons/Home';
import FormControl from '@material-ui/core/FormControl';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ExportCSV } from './ExportReport';
import axios from 'axios';
import configurations from "../../../configurations";
import Loader from "../../Commonscreens/loader";


const useStyles = makeStyles((theme) => ({

    grid: {
        // paddingTop: '10px',
        paddingBottom: '5px',
        background: 'white',
        marginTop: '5px;'
    },

    welcomeTxt: {
        margin: 0,
        marginLeft: '10px',
        marginTop: '5px',
        fontFamily: 'roboto',
        fontSize: '16px',
        textAlign: 'left',
        fontWeight: 400
    },

    welcomeTxtContainer: {
        marginBottom: '5px'
    },
    tableContainer: {
        background: 'white',
        marginTop: '10px;'
    },
}));


const PaidThruReportLanding = (props) => {
    const classes = useStyles();
    // const dispatch  = useDispatch()
    const [invalidDateMessage, setInvalidDateMessage] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [paidThruList, setPaidThruList] = React.useState([]);
    const [paidThruListForCSV, setPaidThruListForCSV] = React.useState([]);
    const [fullPaidThruList, setFullPaidThruList] = React.useState([]);
    const [showLoader, toggleLoader] = useState(false);


    useEffect(() => {

        let premiumPaidDate = moment(new Date()).format('YYYY/MM/DD');
        loadPaidThruReport(premiumPaidDate, true)


    }, []);

    const loginWithPromise = () => {
        return new Promise((resolve, reject) => {
            let token = null
            let request = {
                "username": process.env.REACT_APP_AUTH_USERNAME,
                "password": process.env.REACT_APP_AUTH_TOKEN_PASS
            }
            setTimeout(() => {
                toggleLoader(true);
                axios.post(configurations.csrBaseUrl + 'login', request).then((resp) => {
                    if (resp.headers.authorization) {
                        token = resp.headers.authorization
                        sessionStorage.setItem('csrtoken', token)
                        resolve(token)
                    }
                }).catch((err) => {
                    reject(err)
                    toggleLoader(false);
                });
            }, 100);

        })
    }
    const loadPaidThruReport = (premiumPaidDate, isFullList) => {

        // dispatch({
        //     type: TOGGLE_GLOBAL_LOADER,
        //     payload: true
        // });
        let request = {
            "premiumPaidDate": premiumPaidDate
        }
        loginWithPromise().then((token) => {
            axios.post(configurations.csrBaseUrl + 'csrportal/paiddate', request, {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {
                resp.data.forEach((item) => {
                    item['name'] = item.firstName + ' ' + item.lastName;
                })
                setPaidThruList(resp.data);

                let exportData = resp.data.map((item) => {
                    return {
                        Name: item.name,
                        Channel: item.clientName,
                        DOB: item.dateOfBirth,
                        Email: item.email,
                        Gender: item.gender,
                        'Benefit Begin date': item.benefitBegin,
                        'Paid thru date': item.premiumPaidDate
                    }
                })
                setPaidThruListForCSV(exportData);
                if (isFullList) {
                    setFullPaidThruList(resp.data)
                }
                toggleLoader(false);
                // dispatch({
                //     type: TOGGLE_GLOBAL_LOADER,
                //     payload: false
                // });
            }).catch((err) => {
                setPaidThruList([]);
                toggleLoader(false);
                // dispatch({
                //     type: TOGGLE_GLOBAL_LOADER,
                //     payload: false
                // });
            })
        })


    }
    const handleDateChange = (date) => {
        console.log(date)
        setSelectedDate(date)
        let premiumPaidDate = moment(date).format('YYYY/MM/DD');
        loadPaidThruReport(premiumPaidDate)
    };

    const onDateAccept = (date) => {

    };

    const onDateError = (error) => {
        if (error) {
            // setDisableConfrm(true);
            // setInvalidDateMessage('Invalid Date')
        }
    }

    return (
        <Container maxWidth="lg" style={{ padding: '0 15px' }} className="paidthru-container">
            {
                showLoader && <Loader />
            }
            <Grid container spacing={1} className={classes.grid}>
                <Grid item xs={12} className={classes.welcomeTxtContainer}>
                    <p className={classes.welcomeTxt}> Paid Thru Defaulters Report Of Active Households As Of</p>


                </Grid>
                <Grid item xs={12} className="select-date-grid">

                    <div style={{
                        display: 'flex'
                    }}>
                        <p className="label">Select date: </p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                                invalidDateMessage={invalidDateMessage}
                                // clearable
                                style={{
                                    // width: '55%',
                                    margin: 0,
                                    // marginLeft: '30px',
                                    marginTop: '-5px'
                                }}
                                placeholder="mm/dd/yyyy"
                                margin="normal"
                                id="date-picker-dialog"
                                // label="Date picker dialog"
                                format="MM/dd/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'string',
                                }}
                                disabled={false}
                                // readOnly={true}
                                onAccept={onDateAccept}
                                onError={onDateError}
                            // TextFieldComponent={TextFieldComponent}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        display: 'flex',
                        marginTop: '-3px'
                    }}>
                        <FormControl style={{
                            marginRight: '10px'
                        }}>
                            <Button size="small" color="secondary" 
                            variant="contained"
                            onClick={()=>{
                                props.goBack()
                            }}
                                startIcon={<HomeIcon />}
                            > Home</Button>
                          
                        </FormControl>

                        <FormControl style={{
                            marginRight: '5%'
                        }}>
                            {/* <Button size="small" color="primary" variant="contained"
                                startIcon={<CloudDownloadIcon />}
                            > Report</Button> */}
                            <ExportCSV csvData={paidThruListForCSV} fileName={'PaidThru-Report-' + moment(selectedDate).format('MM/DD/YYYY')} disabled={paidThruList.length <= 0 ? true : false} />
                        </FormControl>
                    </div>

                </Grid>

            </Grid>

            <Grid container spacing={1} className={classes.tableContainer} >
                <PaidThruReport paidThruList={paidThruList} />
            </Grid>
        </Container>
    )

}

export default PaidThruReportLanding