import React, { Component } from 'react'
// import Header from '../Header'
// import './MyNeeds.css'
import './Transaction.css'

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import { gethealthcard, getMyneeds } from '../ApiCall'
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import Button from '@material-ui/core/Button';
// import './transaction.css'
import Sample from './sampleTextField'
import { Modal } from 'react-bootstrap'
import CommonDropDwn from "./CommonDropDwn";
import customStyle from "./stylesheet_UHS";
import CustomeCss from './paymentmode.css.js';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import Radio from '@material-ui/core/Radio';
import RadioChecked from './images/radioButtonChecked.png'
import RadioUnchecked from './images/radioButtonUnchecked.png';
import configurations from '../../../configurations';
import axios from 'axios';
// import TransactionDataTable from './TransactionDataTable'
// import axios from "axios"
// import CommonLoader from './CommonLoader';
const AntTabs = withStyles(
  customStyle.tabs
)(Tabs);

const AntTab = withStyles(theme => (
  customStyle.tab
))(props => <Tab disableRipple {...props} />);

const NextButton = withStyles(
  customStyle.PayButton
)(Button);


export default class ChangePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // rows: myrows
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      disabled: true,
      tableData: [],
      open: false,
      activeTab: 0,
      accountTypes: ["CHECKING", "SAVINGS"],
      bankName: '',
      accountName: localStorage.getItem('subscriberName'),
      accountType: '',
      routingNo: '',
      accountNumber: '',
      cardNumber: '',
      holderName: localStorage.getItem('subscriberName'),
      expiryMonth: '',
      expiryYear: '',
      monthlyDebitDay: '',
      paymentType: '',
      cvv: '',
      selectedValue: '',
      years: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
      tabFirstIcon: RadioChecked,
      tabSecondIcon: RadioUnchecked,
      sourceCode: props.source,
      empid: null,
      disabled2: true,
      errorModal: false,
      MsgModal: '',
      accountNo: null,
      MsgModalerror: '',
      loader: true,
      notfoundmsg: '',
      errCodeCC: false,
      errCodeACH: false,
      MsgModalerrorFooterACH: '',
      MsgModalerrorFooterCC: '',
      reqFlag: true,
    }
  }

  componentDidMount() {

    // this.getTransactionData()
    this.getAccountNumber()

    // this.getSourceCodeFun()


  }


  getAccountNumber = () => {
    axios.get(configurations.paymentBaseUrl + 'transaction/getLast4AccountNumber/' + this.state.sourceCode)
      // getAccountNumber()
      .then(res => {
        // let AccountNo=res.data.response.replaceAll("*", "X");
        if (res.data.response !== null) {
          this.setState({ accountNo: res.data.response.replaceAll("*", "X"), loader: false })
        } else {
          this.setState({ accountNo: '' })
        }
      })
  }


  handleClose = () => {
    this.setState({
      open: false,
      bankName: '',
      accountName: this.state.accountName,
      accountNumber: '',
      accountType: '',
      routingNo: '',

      cardNumber: '',
      holderName: this.state.holderName,
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      paymentType: '',
      MsgModalerror: '',
      errCodeACH: false,
      errCodeCC: false,
      MsgModalerror: '',
      MsgModalerrorFooterACH: '',
      MsgModalerrorFooterCC: ''
    }, () => {
      this.validateForm();
      this.props.closeModal()
    })
  };

  handleClickOpen = () => {
    this.setState({ open: true })
  };


  changeTextFieldHandler = (val, isValid, parentObj) => {
    if (parentObj.label === 'bank_name') {
      if (isValid) {
        this.state.bankName = val;
      } else {
        this.state.bankName = '';
      }
    } else if (parentObj.label === 'Account_Name') {
      if (isValid) {
        this.state.accountName = val;
      } else {
        this.state.accountName = '';
      }
    } else if (parentObj.label === 'Routing_Number') {
      if (isValid) {
        this.state.routingNo = val;
        this.state.errCodeACH = false
      } else {
        this.state.routingNo = '';
      }
    } else if (parentObj.label === 'Account_Number') {
      if (isValid) {
        this.state.accountNumber = val;
      } else {
        this.state.accountNumber = '';
      }
    } else if (parentObj.label === 'Account_Type') {
      if (isValid) {
        this.state.accountType = val;
      } else {
        this.state.accountType = '';
      }
    } else if (parentObj.label === 'Card_Number') {
      if (isValid) {
        // if(this.state.MsgModalerror!=''){
        //  let evt = new CustomEvent('errorCode',{detail:{flag:true}});
        //   window.dispatchEvent(evt);
        // }
        this.state.cardNumber = val;
        this.state.MsgModalerror = ''
        this.state.errCodeCC = ''

      } else {
        this.state.cardNumber = '';
      }
    } else if (parentObj.label === 'Holder_Name') {
      if (isValid) {
        this.state.holderName = val;
      } else {
        this.state.holderName = '';
      }
    } else if (parentObj.label === 'expiryMonth') {
      if (isValid) {
        let currentDate = new Date();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let selectedMon = moment().month(val).format("M");
        if (this.state.expiryYear) {
          if (this.state.expiryYear === year && parseInt(selectedMon) < month) {
            this.state.validMonth = true;
            let evt = new CustomEvent('month', { detail: { flag: true } });
            window.dispatchEvent(evt);
          } else {
            this.state.validMonth = false;
            let evt = new CustomEvent('month', { detail: { flag: false } });
            window.dispatchEvent(evt);
          }
        }
        this.state.expiryMonth = val;
      } else {
        this.state.expiryMonth = '';
      }
    } else if (parentObj.label === 'expiryYear') {
      if (isValid) {
        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let selectedMon = moment().month(this.state.expiryMonth).format("M");
        if (val === year && parseInt(selectedMon) < month) {
          this.state.validMonth = true;
          let evt = new CustomEvent('month', { detail: { flag: true } });
          window.dispatchEvent(evt);
        } else {
          this.state.validMonth = false;
          let evt = new CustomEvent('month', { detail: { flag: false } });
          window.dispatchEvent(evt);
        }
        this.state.expiryYear = val;
      } else {
        this.state.expiryYear = '';
      }
    } else if (parentObj.label === 'cvv') {
      if (isValid) {
        this.state.cvv = val;
      } else {
        this.state.cvv = '';
      }
    } else if (parentObj.label === 'monthlyDebitDay') {
      if (isValid) {
        this.state.monthlyDebitDay = val;
      } else {
        this.state.monthlyDebitDay = '';
      }
    }
    else if (parentObj.label === 'Street') {
      if (isValid) {
        this.state.userAddress.street = val;
      } else {
        this.state.userAddress.street = '';
      }
    }
    this.setState({
      refresh: true
    }, () => this.validateForm());
  };


  validateForm() {
    if (this.state.activeTab === 0) {
      if (this.state.bankName !== '' && this.state.accountName !== '' && this.state.accountNumber !== '' && this.state.accountType !== '' && this.state.routingNo !== '') {
        this.setState({
          disabled: false
        }, () => console.log("====Button===" + this.state.disabled));
      } else {
        this.setState({
          disabled: true
        }, () => console.log("====Button===" + this.state.disabled));
      }
    } else if (this.state.activeTab === 1) {
      if (this.state.cardNumber !== '' && this.state.holderName !== '' && this.state.expiryMonth !== '' && this.state.expiryYear !== '' && this.state.cvv !== '' && !this.state.validMonth) {
        this.setState({
          disabled2: false
        });
      } else {
        this.setState({
          disabled2: true
        });
      }
    }

  }
  handleChange = (event, newValue) => {
    console.log("====================New Value" + newValue)
    this.setState({
      loaderShow: true,
      refresh: true
    });
    let flag;

    if (newValue === 0) {


      this.setState({ activeTab: 0, errCodeACH: this.state.errCodeACH })
      this.setState({ tabFirstIcon: RadioChecked })
      this.setState({ tabSecondIcon: RadioUnchecked })
    } else {

      this.setState({ activeTab: 1 })
      this.setState({ tabFirstIcon: RadioUnchecked })
      this.setState({ tabSecondIcon: RadioChecked })
    }
  }


  handleChangeRadio = (event) => {
    this.setState({ selectedValue: event.target.value })
  }






  changePaymentMode = () => {
    this.setState({ loader: true, MsgModalerror: '' })

    let obj
    var payType = ''
    if (this.state.activeTab == 0) {
      payType = "ACH"
    } else {
      payType = "CC"
    }

    obj = {
      "source": this.state.sourceCode,
      "bankName": this.state.bankName,
      "accountName": this.state.accountName,
      "accountNumber": this.state.accountNumber,
      "accountType": this.state.accountType,
      "routingNo": this.state.routingNo,

      "cardNumber": this.state.cardNumber,
      "holderName": this.state.holderName,
      "expiryMonth": this.state.expiryMonth ? moment().month(this.state.expiryMonth).format("M") : "",
      "expiryYear": this.state.expiryYear,
      "cvv": this.state.cvv,
      "paymentType": payType
    }


    //axios.post('http://3.136.92.227:8085/api/v6/transaction/storeTransaction' ,obj)
    // storeTransaction(obj)
    this.props.toggleLoader(true);
    axios.post(configurations.paymentBaseUrl + 'transaction/storeTransaction', obj)
      .then(res => {

        this.props.toggleLoader(false);
        if (res.data.code == 200) {


          if (this.state.activeTab == 0) {
            this.setState({
              open: false,
              loader: true

              // refresh:true
            });
          }
          if (this.state.activeTab == 0) {
            this.setState({

              open: false,
              loader: true

              //  refresh:true
            });
          }

          this.setState({ errorModal: true, open: false, loader: false, MsgModal: 'Updated payment details successfully!' })
          this.props.getEMPDetails()
          this.props.closeModal();
          this.props.toggleAlertBox('Updated payment details successfully!', true)

        }
        else if (res.data.code == 202) {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(' - ');
          this.setState({ MsgModalerror: cds[1], loader: false, errCodeACH: true })

          if (this.state.activeTab == 0 && this.state.MsgModalerror != '') {

            this.setState({
              errCodeACH: true,
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
              reqFlag: false,

            })

            let evt = new CustomEvent('errorCode', { detail: { flag: true } });
            window.dispatchEvent(evt);
          }

          if (this.state.activeTab == 1) {

            this.setState({
              errCodeCC: true,
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
              errCodeACH: false,
            })

            let evt = new CustomEvent('errorCode', { detail: { flag: true } });
            window.dispatchEvent(evt);
          }

        }
        else if (res.data.code == 204) {

          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: 'Source is not registered',
              loader: false,
              MsgModalerrorFooterCC: '',
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: 'Source is not registered',
              loader: false,
              MsgModalerrorFooterACH: '',
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
            })
          }

        }
        else if (res.data.code == 500) {

          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: 'Internal server error',
              loader: false, MsgModalerrorFooterCC: '',
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: 'Internal server error',
              loader: false, MsgModalerrorFooterACH: '',
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',

            })
          }
        }

        else {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(' - ');
          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: cds[1], loader: false,
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: cds[1], loader: false,
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
            })
          }
        }
      }).catch((err) => {
        this.props.toggleLoader(false);
      })
  }

  handleCloseErrorModal = () => {
    this.getAccountNumber()
    this.setState({
      errorModal: false,

      bankName: '',
      accountName: this.state.accountName,
      accountNumber: '',
      accountType: '',
      routingNo: '',

      cardNumber: '',
      holderName: this.state.holderName,
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      paymentType: '',
      MsgModalerror: '',
      errCodeACH: false,
      errCodeCC: false
    })
  }

  goBack = () => {
    this.props.history.push('/')
  }
  render() {
    const { classes } = this.props

    // console.log("=======table data is======",this.state.tableData)


    let currentScreen = '';
    if (this.state.activeTab === 0) {
      currentScreen = <div >
        <Grid container spacing={1} style={{ marginTop: '1%' }}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Bank_Name'} reqFlag={true} label={'Bank Name'} value={this.state.bankName} disable={false} style={CustomeCss.textField} length={120} fieldType={'bank_name'} errMsg={'Enter valid bank name'} helperMsg={'Bank name required'} parentDetails={{ label: 'bank_name' }} key={0}></Sample>
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Name'} reqFlag={true} label={'Name on Account'} value={this.state.accountName} disable={false} style={CustomeCss.textField} length={120} fieldType={'accountName'} errMsg={'Enter valid account name'} helperMsg={'Name on account required'} parentDetails={{ label: 'Account_Name' }} key={0}></Sample>
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'AccountType'} label={'Account Type'} value={this.state.accountType} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select account type'} List={this.state.accountTypes} parentDetails={{ label: 'Account_Type' }}></CommonDropDwn>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '1.5%' }}>
              <Grid item xs={12} sm={6} style={{ marginBottom: '-2%' }} >
                {
                  this.state.MsgModalerror != '' ?
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={this.state.MsgModalerror} parentDetails={{ label: 'Routing_Number' }} key={0}></Sample>

                    :
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={'Routing number required'} parentDetails={{ label: 'Routing_Number' }} key={0}></Sample>

                }    </Grid>
              <Grid item xs={12} sm={6}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Number'} reqFlag={true} label={'Account Number'} value={this.state.accountNumber} disable={false} style={CustomeCss.textField} length={26} fieldType={'accountNumber'} errMsg={'Account number Should be minimum of 4 digit'} helperMsg={'Account number required'} parentDetails={{ label: 'Account_Number' }} key={0}></Sample>
              </Grid>
            </Grid>
          </Grid>


        </Grid>



      </div>
    } else if (this.state.activeTab === 1) {
      currentScreen = <div >

        <Grid container spacing={1} style={{ marginTop: '1%' }}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={6} style={{ marginBottom: '-3%' }}>
                {
                  this.state.MsgModalerror != '' ?
                    //  <span>{this.state.MsgModalerror}</span>
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 15 or 16 digits'} helperMsg={this.state.MsgModalerror} parentDetails={{ label: 'Card_Number' }}></Sample>

                    :
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 15 or 16 digits'} helperMsg={'Card number required'} parentDetails={{ label: 'Card_Number' }}></Sample>

                }
              </Grid>
              <Grid item xs={12} sm={6} >
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Holder_Name'} reqFlag={true} label={'Card Holder Name'} value={this.state.holderName} disable={false} style={CustomeCss.textField} length={25} fieldType={'holderName'} errMsg={'Enter valid card holder name'} helperMsg={'Card holder name required'} parentDetails={{ label: 'Holder_Name' }}></Sample>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '2%' }}>
              <Grid item xs={12} sm={4}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryMonth'} label={'Expiration Month'} value={this.state.expiryMonth} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select expiration month'} errMsg={"The expiration date is before today's date. Enter valid expiration month"} List={this.state.months} parentDetails={{ label: 'expiryMonth', val: this.state.expiryYear }} key={1}></CommonDropDwn>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryYear'} label={'Expiration Year'} value={this.state.expiryYear} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select expiration year'} errMsg={"The expiration date is before today's date. Enter valid expiration year"} List={this.state.years} parentDetails={{ label: 'expiryYear', val: this.state.expiryMonth }} key={1}></CommonDropDwn>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'cvv'} label={'CVV'} reqFlag={true} value={this.state.cvv} disable={false} style={CustomeCss.textField} length={4} fieldType={'cvv'} errMsg={'Enter valid CVV'} helperMsg={'CVV required'} parentDetails={{ label: 'cvv' }} key={1}></Sample>
              </Grid>
            </Grid>

          </Grid>


        </Grid>


      </div>
    }
    return (


      <Modal size="lg" show={this.props.open} onHide={this.handleClose} centered className="ChangPayModal" backdrop='static'>

        <Modal.Body>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <span class="Change-Payment-Method">Change Payment Method</span>
            <IconButton aria-label="close" onClick={this.handleClose} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} >
              <p class="The-fellowship-incurs-additional-fees-related-to-p">Change Payment operation will change the account on file and will not charge the member.
                  {/* <br/><br/>Last used account:{this.state.accountNo}  */}
                {/* {this.state.accountNo!=null?
                 <> {this.state.accountNo} this is your last used account for recurring.</>
                  :""} */}
              </p>

            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} >
              <AntTabs
                value={this.state.activeTab}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
              >

                <AntTab label="ACH DEBIT" className='ant-col-15 tabBtn tabFirst' icon={<img className="raidoBtn" src={this.state.tabFirstIcon} />} />
                <AntTab label="CREDIT CARD" className='ant-col-15 tabBtn tabSecond' icon={<img className="raidoBtn" src={this.state.tabSecondIcon} />} />

              </AntTabs>
            </Grid>
          </Grid>
          <div >
            {/* <span>  Account Number currently on file XXXXXX4567</span> */}


          </div>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"

          >
            <Grid item xs={12} sm={3} md={4} >
              <table className="Last_4_digit" >
                <td className="td_style1">Account Number currently on file</td>
                <td className="td_style" style={{ verticalAlign: 'middle' }}>
                  {/* XXXXXX4567 */}{this.state.accountNo}
                </td>
              </table>
            </Grid>

          </Grid>
          {currentScreen}
        </Modal.Body>
        <Modal.Footer>

          {this.state.activeTab == 0 ?

            <>
              <p className="Footer-error-msg" style={{
                //width: '500px'
              }}>{this.state.MsgModalerrorFooterACH}</p>
              <Button variant="secondary" onClick={this.handleClose} style={{ marginRight: '15px' }} class="ButtonBG">
                CANCEL
                  </Button>
              <NextButton variant="contained" disabled={this.state.disabled}
                color="primary" onClick={this.changePaymentMode} >
                DONE
                  </NextButton>
            </>
            :
            <>
              <p className="Footer-error-msg" style={{
                //width: '500px' 
              }}>{this.state.MsgModalerrorFooterCC}</p>

              <Button variant="secondary" onClick={this.handleClose} style={{ marginRight: '15px' }} class="ButtonBG">
                CANCEL
                  </Button>
              <NextButton variant="contained" disabled={this.state.disabled2}
                color="primary" onClick={this.changePaymentMode} >
                DONE
                  </NextButton>
            </>
          }



        </Modal.Footer>
      </Modal>
    )
  }
}

// export default withStyles(useStyles)(MyNeeds)

