import React, { useEffect, useState } from 'react';
import moment from "moment";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
// import apiService from '../../utils/apiservice';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
// import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
// import RefundPayment from './RefundPayment';
// import { DialogTitle } from './ViewHealthQuestions'

import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import RefundPayment from './RefundPayment';
import configurations from "../../../configurations";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    button: {
        marginLeft: '10px',
        textTransform: 'none',
        fontSize: '11px',
        marginBottom: '5px'

    },
});



const rows = [
    {
        id: 1234,
        amount: 500,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 111,
        amount: 100,
        status: 'Pending',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 222,
        amount: 200,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 333,
        amount: 1500,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Refund',
        description: 'some reason for Refund'
    }, {
        id: 444,
        amount: 5400,
        status: 'Failed',
        Date: '21-08-2020',
        isRefund: true,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    },
];

export default function ViewTransactionHistory(props) {
    const classes = useStyles();
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [trasctionDataForRefund, setTrasctionDataForRefund] = useState(false);

    const [showRefundPaymentVIew, toggleRefundPaymentView] = React.useState(false);
    const [enableRefund, toggleRefund] = React.useState(false);

    useEffect(() => {

        loadTransactionHistory();
    }, []);

    const loadTransactionHistory = () => {
        props.globaLoader(true);
        // let searchValue = 'swapnalees@perscitussln.com';
        // let searchValue = 'anshula80@yopmail.com';
        // let searchValue = props.userData.email;
        let searchValue = props.userData.memberIdSource
        console.log('::memberIdSource::', props.userData.memberIdSource)

        axios.get(configurations.paymentBaseUrl + 'transaction/transactionHistory?searchValue=' + searchValue + '&searchKey=source')
            // apiService.get(process.env.REACT_APP_BASE_URL + 'v6/transaction/transactionHistory?searchValue=' + searchValue + '&searchKey=email', true)
            .then((response) => {
                console.log(response)
                if (response && response.data && response.data.response) {
                    setTransactionHistory(response.data.response)
                } else {
                    setTransactionHistory([])
                }

                props.globaLoader(false);
            }).catch((error) => {
                setTransactionHistory([])
                props.globaLoader(false);
            });
    }
    const handleClose = () => {
        props.toggleRegistrationStatus(false)
    };
    const closerefndpayment = () => {
        toggleRefundPaymentView(false)
    };

    const onRefundClick = (transaction) => {
        setTrasctionDataForRefund(transaction);
        toggleRefundPaymentView(true)
    }

    const confirmRefund = (data) => {

        if (trasctionDataForRefund) {
            props.globaLoader(true);
            let request = {
                "transactionId": trasctionDataForRefund.transactionId,
                "refundAmount": parseFloat(data.amount),
                "refundDescription": data.reason,
                "affiliationId": trasctionDataForRefund.affiliationId

            }
            // apiService.put(process.env.REACT_APP_BASE_URL + 'v6/transaction/refund', request, true)
            //     .then((response) => {
            //         props.toggleAlertBox(response.data.message, true, 'Alert')

            //         props.globaLoader(false);
            //         setTrasctionDataForRefund(false);
            //         loadTransactionHistory()
            //     }).catch((error) => {

            //         props.globaLoader(false);
            //         props.toggleAlertBox('Request Failed!', true, 'Alert')
            //         //handle fail
            //         // setTrasctionDataForRefund(false);
            //     });
            closerefndpayment();

        } else {
            closerefndpayment();
        }

    }

    const getTransactionMsg = (transaction) => {
        let description = ''
        if (transaction.transactionStatus == 'FAIL') {
            let reason = transaction.refundDescription
            if (transaction.reason) {
                try {
                    reason = JSON.parse(transaction.reason).error_message
                }
                finally {
                    return reason
                }
            }
        }

        if (transaction.reason && transaction.reason.length > 0) {
            description = transaction.reason
        }
        description = description + ' ' + (transaction.refundDescription ? transaction.refundDescription : '')
        return description;
    }

    const showRefundBtn = (transaction) => {
        let amountToRefnd = transaction.transactionAmount - transaction.refundAmount;
        let hasAmountToRefund = amountToRefnd && amountToRefnd > 0;

        if (transaction.indicatorFlag == 'PAYMENT' && hasAmountToRefund && transaction.authorizationMessage == 'APPROVED') {
            return <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                // startIcon={<ContactMailIcon />}
                onClick={() => onRefundClick(transaction)}

            >
                Refund
            </Button>

        }
        return null;


    }
    return (
        <div className={classes.root}>

            <Table className={`${classes.table} transaction-table-list`} size="small" aria-label="a dense table" hidden={transactionHistory.length <= 0}>
                <TableHead>
                    <TableRow styl={{
                        background: '#dedede'
                    }}>
                        <TableCell colSpan={2} align="center">Date</TableCell>
                        <TableCell style={{
                            // maxWidth: '100px'
                        }}>Transaction ID</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Authorization</TableCell>
                        <TableCell align="left"> Month</TableCell>
                        {/* <TableCell align="left">Status</TableCell> */}
                        <TableCell align="left" style={{ maxWidth: '300px' }}>Description</TableCell>
                        {
                            enableRefund &&
                            <TableCell align="left">Refund</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionHistory.map((transaction, index) => (
                        <Row key={transaction.transactionId} row={transaction} />

                        // <TableRow key={index}>
                        //     <TableCell align="left">{transaction.createdDate}</TableCell>
                        //     <TableCell >
                        //         {transaction.transactionId ? transaction.transactionId : ' - '}
                        //     </TableCell>
                        //     <TableCell align="left">{transaction.indicatorFlag ? transaction.indicatorFlag : '      - '}</TableCell>
                        //     <TableCell align="left">{transaction.type}</TableCell>
                        //     <TableCell align="left">{transaction.transactionAmount ?
                        //         '$ ' + transaction.transactionAmount : '      - '} </TableCell>
                        //     <TableCell align="left">{
                        //         transaction.authorizationMessage ? transaction.authorizationMessage : '      - '
                        //     }</TableCell>

                        //     <TableCell align="left">{transaction.paymentNumber}</TableCell>

                        //     {/* <TableCell align="left">{
                        //         transaction.transactionStatus
                        //     }</TableCell> */}
                        //     <TableCell align="left" style={{ maxWidth: '300px' }}>{
                        //         getTransactionMsg(transaction)
                        //     }</TableCell>

                        //     {
                        //         enableRefund &&
                        //         <TableCell align="left">{showRefundBtn(transaction)}</TableCell>
                        //     }


                        // </TableRow>
                    ))}
                </TableBody>
            </Table>
            {
                // showRefundPaymentVIew &&
                // <RefundPayment isOpen={showRefundPaymentVIew} closerefndpayment={closerefndpayment} confirmRefund={confirmRefund} trasctionDataForRefund={trasctionDataForRefund} />
            }

            <p hidden={transactionHistory.length > 0}>No Data Found</p>

        </div >
    );
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset'
        },
    }
})

function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()

    const [modalShow, setModalShow] = React.useState(false);

    const getDate = (date) => {
        const isDateValid = moment(date)['_isValid'];
        if (isDateValid) {
            return moment(date).format('MMMM D, YYYY');
        }
        return date;
    }
    console.log("Row----", props)

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const floatconversion = (x) => {
        return x.toFixed(2)
    }

    //function takes in two dates and add the dash between them to show the continuation
    const combineDate = (x, y) => {
        if (x == y) { return x }

        const z = (x + '-' + y)
        return z


    }
    const theme = createMuiTheme({
        MuiTableCell: {
            paddingLeft: '30px',
        }
    });



    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <TableRow className={'rowTable ' + classes.root}>
                    <TableCell className="rowTableCell">
                        <IconButton style={{
                            color: '#000'
                        }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" className="rowcellcolor rowTableCell"  >
                        {getDate(row.createdDate)}
                    </TableCell>
                    <TableCell component="th" scope="row" className="rowcellcolor rowTableCell"  >
                        {row.transactionId != null ? row.transactionId : '0'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.indicatorFlag != null ? row.indicatorFlag : 'NA'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.type != null ? row.type : 'NA'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        ${row.transactionAmount != null ? row.transactionAmount.toFixed(2) : 'NA'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.authorizationMessage != null ? row.authorizationMessage : 'NA'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.paymentNumber != null ? row.paymentNumber : '0'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {(row.reason != null || row.refundDescription != null) ? row.reason != null ? row.reason : '' + ' ' + row.refundDescription != null ? row.refundDescription : '' : 'NA'}

                    </TableCell>

                </TableRow>









                {/* The Following table row is used to for the information of the dropdown rows that explain the EOS */}
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" component="tr" unmountOnExit style={{ backgroundColor: '#f6f6f6' }}>
                            <Box>
                                {/* <Table className="collapseTableContainer" size="small" aria-label="purchases">
                 <TableHead> */}
                                <TableRow>
                                    <TableCell component="td" align="left" className="innerTh blankCell" style={{ width: '4%' }}>
                                        &nbsp;
                   </TableCell>
                                    <TableCell component="td" align="left" className="innerTh blankCell" style={{ width: '4%' }}>
                                        &nbsp;
                   </TableCell>
                                    <TableCell align="left" className="innerTh" style={{ width: '317px' }}>
                                        Monthly Fee
                   </TableCell>
                                    <TableCell align="left" className="innerTh" style={{ width: '404px' }}>
                                        Monthly Share
                   </TableCell>
                                    <TableCell align="left" className="innerTh" style={{ width: '317px' }}>
                                        Application Fee
                   </TableCell>
                                    {/* <TableCell align="left" className="innerTh" style={{ width: '382px' }}>
                                        Health Tools
                   </TableCell>
                                    <TableCell align="left" className="innerTh" style={{ width: '341px' }}>
                                        RXSimpleShare
                   </TableCell> */}





                                </TableRow>
                                {/* </TableHead> */}

                                <TableBody>
                                    {/* {row.inlineData.map((item) => ( */}
                                    <TableRow
                                        style={{
                                            background: '#fff'
                                        }}
                                    // key={item.checkNumber}
                                    >
                                        <TableCell component="th" scope="row" className="innerTh blankCell" align="left">
                                            &nbsp;
                     </TableCell>
                                        <TableCell component="th" scope="row" className="innerTh blankCell" align="left">
                                            &nbsp;
                     </TableCell>


                                        <TableCell align="left" className="innerTh">
                                            {
                                                <>${row.uhfMonthlyFee != null ? row.uhfMonthlyFee : '0'}</>

                                            }
                                        </TableCell>

                                        <TableCell align="left" className="innerTh">
                                            {
                                                <>${row.monthlyShare != null ? row.monthlyShare : '0'}</>

                                            }
                                        </TableCell>

                                        <TableCell align="left" className="innerTh">
                                            {
                                                <>${row.applicationFee != null ? row.applicationFee : '0'}</>
                                            }
                                        </TableCell>

                                        {/* <TableCell align="left" className="innerTh">
                                            {
                                                <>${row.healthToolAmount != null ? row.healthToolAmount : '0'}</>
                                            }
                                        </TableCell>

                                        <TableCell align="left" className="innerTh">
                                            <>${
                                                row.rxsimpleShareAmount != null ? row.rxsimpleShareAmount : '0'
                                            }
                                            </>
                                        </TableCell> */}







                                        {/* <TableCell align="left" className="innerTh">
                                      {row.refundAmount}
                                   </TableCell> */}
                                        {/* <TableCell align="left" className="innerTh">
                       {row.refundDescription}
                     </TableCell> */}


                                    </TableRow>
                                    {/* ))} */}
                                </TableBody>

                                {/* </Table> */}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                {/* <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    data={row}
                /> */}
            </ThemeProvider>
        </React.Fragment>
    )
}