import React, { Component } from "react";
import { Auth } from "aws-amplify";
import './style.css'
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';


export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    this.loadQueryParams();
  }

  loadQueryParams = () => {
    let queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('username') && queryParams.has('password')) {
      let userName = decodeURI(queryParams.get('username'));
      let password = decodeURI(queryParams.get('password'));
      this.emailInput.current.value = userName;
      this.passwordInput.current.value = password;

      let usernameObj = {
        target: {
          name: 'username',
          value: userName
        }
      }
      this.props.handleChange(usernameObj, true);

      let passwordObj = {
        target: {
          name: 'password',
          value: password
        }
      }
      this.props.handleChange(passwordObj, true)
    }
  }

  handleSubmit = (event) => {
    this.props.handleSubmit(event)
  }

  handleChange = (event) => {
    this.props.handleChange(event)
  }
  handleForgot = (event) => {
    console.log('inside handle logout')
    this.props.handleForgot(event)
  }

  render() {
    return (
      <div className="login">
        <CssBaseline />
        <Container maxWidth="xs">
          <Card className="login-card" style={{ marginTop: "50px" }}>

            <div className="logo">
              <img alt="logo" className="logo-custom" src={require('./images/Curam_Logo.png')} />
            </div>
            <form onSubmit={this.handleSubmit.bind(this)} className="main-form">
              <h4 className="label-head">Sign in with your email and password</h4>
              <p className="a-errorMessage" hidden={this.props.errorMsg.length <= 0}>{this.props.errorMsg}</p>
              <div className="a-form-ctrl">
                <p className="">Email</p>
                <input ref={this.emailInput}
                  className="a-input"
                  type="text"
                  placeholder="Enter Email"
                  name="username"
                  required
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              <div className="a-form-ctrl">
                <p className="">Password</p>
                <input ref={this.passwordInput}
                  className="a-input"
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  required
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <span className="forgot-pawd" onClick={this.handleForgot.bind(this)}>Forgot your password?</span>

              <div>
                <button type="submit" className="a-btn" disabled={this.props.disableSiginBtn}>
                  Sign In
                </button>
                {/* <p style={{ textAlign: 'center' }}><span>Need an account?</span>&nbsp;
                <span className="forgot-pawd"><Link to={"/signup" + window.location.hash }>Sign Up</Link></span></p> */}
              </div>

              {/* 

              <TableContainer component={Paper}>
                <Table aria-label="simple table">

                  <TableRow>
                    <TableCell>
                      <label for="email">
                        <b>Username</b>
                      </label></TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        name="username"
                        required
                        onChange={this.handleChange}
                      /></TableCell></TableRow>
                  <TableRow>
                    <TableCell>

                      <label for="psw">
                        <b>Password</b>
                      </label></TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Password"
                        name="password"
                        required
                        onChange={this.handleChange}
                      /></TableCell></TableRow>
                  <TableRow>
                    <TableCell>


                      <button type="button" class="forgot" onClick={this.handleForgot} name="forgot">
                        forgot password
                </button></TableCell><TableCell>
                      <button type="submit" class="signupbtn">
                        Sign In
                </button></TableCell>
                  </TableRow>
                </Table>
              </TableContainer> */}



            </form>

          </Card>

        </Container>
      </div>

    )

  }
}
export default SignIn;
